/* From Uiverse.io by alexmaracinaru */
.card {
  width: 220px;
  height: 284px;
  background: rgb(183, 226, 25);
  font-family: inherit;
  position: relative;
  border-radius: 8px;
}

.quote {
  color: rgb(223, 248, 134);
  padding-left: 30px;
  position: relative;
}

.card_name {
  text-transform: uppercase;
  font-weight: 700;
  color: rgb(127, 155, 29);
  padding: 35px;
  line-height: 23px;
}

.body_text {
  font-size: 20px;
  font-weight: 900;
  padding: 60px 40px 0;
  color: #465512;
  position: absolute;
  top: 40px;
  left: 1px;
  line-height: 23px;
}

.author {
  margin-top: 5px;
  opacity: 0;
  transition: 0.5s;
}

.card .author {
  opacity: 1;
}

.card:hover {
  margin-top: -6%;
  box-shadow: 0 0 0 15px rgb(223, 248, 134);
  cursor: pointer;
}

.pic {
  width: 50px;
  height: 50px;
  background-color: rgb(158, 196, 21);
  border-radius: 50%;
}

.author-container {
  display: flex;
  align-items: center;
}

.author {
  font-weight: 700;
  color: rgb(127, 155, 29);
  padding-left: 30px;
}

.card .author svg {
  display: inline;
  font-size: 12px;
  color: rgba(128, 155, 29, 0.452);
}