.otpform {
  /* width: 90%; */
  height: 380px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  transition: 0.4s ease-in-out;
}

.otpform:hover {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  scale: 0.99;
}

.otpheading {
  position: relative;
  text-align: center;
  color: black;
  top: 3em;
  font-weight: bold;
  margin-bottom: 3rem;
}

.otpinput::-ms-reveal {
  display: none;
}
.otpinput {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  margin: 1em;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: rgb(235, 235, 235);
  box-shadow: inset 3px 3px 6px #d1d1d1;
  top: 6.5em;
  padding-left: 15px;
  transition: 0.4s ease-in-out;
  /* font-size: large; */
}
.otpinputFirstPass {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  margin: 1em;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: rgb(235, 235, 235);
  box-shadow: inset 3px 3px 6px #d1d1d1;
  /* top: 1em; */
  padding-left: 15px;
  transition: 0.4s ease-in-out;
  /* font-size: large; */
}
.otpinput:hover {
  box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
  background-color: lightgrey;
}

.otpinput:focus {
  box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
  background-color: lightgrey;
}

.otpbtn1 {
  position: relative;
  top: 8.5em;
  margin: 5%;
  height: 3em;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;
  margin-top: 2rem;
}

.otpbtn1:active {
  box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
}
