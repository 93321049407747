.layout-cards {
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1.5rem;
  background: #65d3ff;
}
.layout-cards .dim-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: black;
  opacity: 0;
  pointer-events: none;
}
.layout-cards .opened-card {
  border-radius: 15px;
  cursor: pointer;
  height: calc(100% - 10rem);
  width: 40rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.layout-cards .opened-card div {
  background: white;
  height: 100%;
  width: 100%;
  margin: 0 1rem;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.layout-cards .opened-card div:first-child {
  margin-left: 0;
}
.layout-cards .card {
  background: white;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.layout-cards .card:nth-child(1) {
  grid-column: 1 / span 2;
  grid-row: 1;
}
.layout-cards .card:nth-child(2) {
  grid-column: 3;
  grid-row: 1;
}
.layout-cards .card:nth-child(3) {
  grid-column: 1;
  grid-row: 2;
}
.layout-cards .card:nth-child(4) {
  grid-column: 2 / span 2;
  grid-row: 2;
}
.layout-cards .cards-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 33rem;
  max-width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.layout-cards .cards-container::-webkit-scrollbar {
  display: none;
}
