@font-face {
  font-family: "Hybrid_b"; /*Can be any text*/
  src: local("Hybrid_b"), url("./fonts/Hybrid_b.ttf") format("truetype");
}

@font-face {
  font-family: "hybrid_o"; /*Can be any text*/
  src: local("hybrid_o"), url("./fonts/hybrid_o.ttf") format("truetype");
}
@font-face {
  font-family: "Pricetag-Regular"; /*Can be any text*/
  src: local("Pricetag-Regular"),
    url("./fonts/Pricetag-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Compact"; /*Can be any text*/
  src: local("Compact"), url("./fonts/Compact.ttf") format("truetype");
}

@font-face {
  font-family: "Oswald-Regular"; /*Can be any text*/
  src: local("Oswald-Regular"), url("./fonts/Oswald-Regular.ttf") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
