.modal {
  background-color: #fff;
  box-shadow: 0 10px 60px rgb(218, 229, 255);
  border: 1px solid rgb(159, 159, 160);
  font-size: 1.125rem;
  border-radius: 1em;
  color: rgb(15, 14, 14);
  /* max-width: 50%; */
  overflow: visible;
  position: relative;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 3rem;
}

.content > *,
.modal > * {
  padding: 0.875em;
}

.title {
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.2;
  display: flex;
  justify-content: center;
}

.message {
  line-height: 1.2;
  text-align: center;
}

.actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.upload-btn {
  background-color: transparent;
  border: 0.125rem dashed hsla(223, 10%, 50%, 0.4);
  flex: 1;
  padding: 0.375rem 2rem;
}

.upload-btn:hover {
  background-color: hsla(223, 10%, 60%, 0.2);
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(226, 94, 54);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  /* right: 10px; */
  /* top: 10px; */
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.result {
  margin-top: 2rem;
  background-color: rgba(0, 140, 255, 0.062);
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 1em;
}

.drop-container {
  background-color: #fff;
  width: 100%;
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 2.1875rem;
  border-radius: 10px;
  border: 2px dashed rgb(171, 202, 255);
  color: #444;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

/* .drop-container:hover {
  background: rgba(0, 140, 255, 0.164);
  border-color: rgba(17, 17, 17, 0.616);
} */

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
  margin-bottom: 3rem;
}

#file-input {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 2px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid rgba(8, 8, 8, 0.288);
}

#file-input::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

#file-input::file-selector-button:hover {
  background: #0d45a5;
}

.billWidth {
  width: 460px;
  align-items: center;
  justify-content: center;
  align-content: center;
}
