.par {
  display: flex;
  flex-direction: row;
  /* height: 90vh; */
  /* background-color: #61dafb; */
  overflow: hidden;
  position: relative;
  height: 115%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  top: 49%;
  right: -48%;
  margin-top: 4%;
}

.par::before {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: block;
  left: 0;
  padding: 0.25em;
  position: absolute;
  top: 0;
  z-index: 10;
}

iframe {
  display: block; /* iframes are inline by default */
  height: 100vh; /* Set height to 100% of the viewport height */
  width: 100vw; /* Set width to 100% of the viewport width */
  max-width: 100%;
  border: none; /* Remove default border */
  /* background: lightyellow; Just for styling */
}

/* .fitToWidthOrigin {
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  top: 0;
  left: 0;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
} */
